const logo = () => (
  <svg width="73" height="52" viewBox="0 0 73 52" fill="none">
    <path
      d="M6.6929 23.215H13.0895C13.398 23.215 13.648 22.9649 13.648 22.6565V16.9587C13.6497 16.6502 13.4011 16.3988 13.0927 16.3971C13.0916 16.3971 13.0905 16.3971 13.0895 16.3971H6.6929C6.38439 16.3971 6.1344 16.6472 6.1344 16.9556C6.1344 16.9566 6.1344 16.9577 6.1344 16.9587V22.6564C6.1344 22.9649 6.38439 23.215 6.6929 23.215Z"
      fill="#2496ED"
    />
    <path
      d="M15.5079 23.215H21.9045C22.213 23.215 22.463 22.9649 22.463 22.6565V16.9587C22.4647 16.6502 22.2161 16.3988 21.9076 16.3971C21.9066 16.3971 21.9054 16.3971 21.9044 16.3971H15.5078C15.1976 16.3971 14.9462 16.6485 14.9462 16.9587V22.6564C14.948 22.9654 15.1989 23.215 15.5079 23.215Z"
      fill="#2496ED"
    />
    <path
      d="M24.4568 23.215H30.8534C31.1619 23.215 31.4119 22.9649 31.4119 22.6565V16.9587C31.4136 16.6502 31.165 16.3988 30.8565 16.3971C30.8554 16.3971 30.8543 16.3971 30.8533 16.3971H24.4567C24.1482 16.3971 23.8982 16.6472 23.8982 16.9556C23.8982 16.9566 23.8982 16.9577 23.8982 16.9587V22.6564C23.8983 22.9649 24.1484 23.215 24.4568 23.215Z"
      fill="#2496ED"
    />
    <path
      d="M33.2997 23.215H39.6963C40.0053 23.215 40.2562 22.9655 40.258 22.6565V16.9587C40.258 16.6485 40.0066 16.3971 39.6963 16.3971H33.2997C32.9912 16.3971 32.7412 16.6472 32.7412 16.9556C32.7412 16.9566 32.7412 16.9577 32.7412 16.9587V22.6564C32.7412 22.9649 32.9913 23.215 33.2997 23.215Z"
      fill="#2496ED"
    />
    <path
      d="M15.5078 15.018H21.9044C22.2133 15.0163 22.4629 14.7653 22.4629 14.4563V8.75869C22.4629 8.45019 22.2128 8.2002 21.9044 8.2002H15.5078C15.1989 8.2002 14.9479 8.44968 14.9462 8.75869V14.4563C14.9479 14.7659 15.1983 15.0163 15.5078 15.018Z"
      fill="#2496ED"
    />
    <path
      d="M24.4568 15.018H30.8534C31.1623 15.0163 31.4119 14.7653 31.4119 14.4563V8.75869C31.4119 8.45019 31.1618 8.2002 30.8534 8.2002H24.4568C24.1483 8.2002 23.8983 8.45029 23.8983 8.75869V14.4563C23.8983 14.7653 24.1479 15.0163 24.4568 15.018Z"
      fill="#2496ED"
    />
    <path
      d="M33.2997 15.018H39.6963C40.0058 15.0163 40.2562 14.7659 40.258 14.4563V8.75869C40.2562 8.44968 40.0053 8.2002 39.6963 8.2002H33.2997C32.9912 8.2002 32.7412 8.45029 32.7412 8.75869V14.4563C32.7412 14.7653 32.9908 15.0163 33.2997 15.018Z"
      fill="#2496ED"
    />
    <path
      d="M33.2997 6.8179H39.6963C40.0053 6.8179 40.2562 6.56831 40.258 6.2594V0.558498C40.2562 0.249484 40.0053 0 39.6963 0H33.2997C32.9912 0 32.7412 0.249991 32.7412 0.558498V6.2593C32.7412 6.56781 32.9913 6.8179 33.2997 6.8179Z"
      fill="#2496ED"
    />
    <path
      d="M42.2207 23.215H48.6173C48.9264 23.215 49.1773 22.9655 49.179 22.6565V16.9587C49.179 16.6485 48.9276 16.3971 48.6173 16.3971H42.2207C41.9123 16.3971 41.6622 16.6472 41.6622 16.9556C41.6622 16.9566 41.6622 16.9577 41.6622 16.9587V22.6564C41.6622 22.9649 41.9123 23.215 42.2207 23.215Z"
      fill="#2496ED"
    />
    <path
      d="M71.7513 19.6298C71.5547 19.4737 69.72 18.0883 65.8508 18.0883C64.8291 18.0928 63.8096 18.1804 62.8021 18.3504C62.0533 13.2175 57.8096 10.715 57.6193 10.6027L56.5803 10.0035L55.897 10.9896C55.0412 12.3142 54.4165 13.7744 54.0498 15.3081C53.357 18.2381 53.7783 20.9901 55.2667 23.3429C53.4694 24.3445 50.5862 24.591 50.0028 24.6128H2.26838C1.02157 24.6146 0.00983906 25.6221 0.00304418 26.8688C-0.0526332 31.0506 0.655859 35.2075 2.09364 39.1347C3.738 43.447 6.18435 46.6235 9.36709 48.5674C12.9336 50.7516 18.728 51.9997 25.2962 51.9997C28.2634 52.0091 31.2251 51.7405 34.1423 51.1978C38.1976 50.4532 42.0997 49.0359 45.6874 47.0041C48.6436 45.2922 51.3042 43.1144 53.5662 40.5544C57.348 36.2733 59.601 31.5055 61.2765 27.2682C61.5011 27.2682 61.7259 27.2682 61.9442 27.2682C66.0848 27.2682 68.6311 25.6113 70.0352 24.2228C70.9683 23.3372 71.6968 22.2585 72.1695 21.0619L72.4659 20.1945L71.7513 19.6298Z"
      fill="#2496ED"
    />
  </svg>
)
export default logo
